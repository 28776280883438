const jp = {
    home: "ホーム",
    app: "アプリ",
    stories: "物語",
    smartsearch: "スマート検索...",
    read_more: "続きを読む",
    footer_msg1: "蘆花は『まどひ白きの神隠し』で登場したキャラクター",
    // Add more translations here
  };

  export default jp;
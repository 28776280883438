const en = {
    home: "Home",
    app: "App",
    stories: "Stories",
    smartsearch: "Smart Search...",
    read_more: "Read More",
    footer_msg1: "Roka is a game character from Madohi Shiroki no Kamikakushi",
    // Add more translations here
  };

  export default en;
import React, { useState } from 'react';
import './software.css';
import { translate } from '../../utilities/translate';

// Mock data for software releases, replace with real data fetching logic
const mockApps_en = [
  {
    id: 1,
    title: 'Go game PC software',
    category: 'Go',
    date: '2024-03-22',
    intro: 'An introduction to the essential PC software for playing Go',
    link: '/stories/3', 
  },
  // Add more here
  {
    id: 2,
    title: 'Printing issues',
    category: 'Printing',
    date: '2024-04-08',
    intro: 'Some printing issues and their solutions',
    link: '/stories/10',
  },
  {
    id: 3,
    title: 'Minecraft Server',
    category: 'Server',
    date: '2024-04-13',
    intro: 'My Minecraft server is finally online!',
    link: '/stories/11',
  },
  {
    id: 4,
    title: 'Battle Simulator',
    category: 'Game',
    date: '2024-06-24',
    intro: 'Original app by Rokafox',
    link: '/stories/13',
  },
  {
    id: 5,
    title: 'Troubleshooting',
    category: 'OS',
    date: '2024-08-14',
    intro: 'Dealing with various errors on Linux.',
    link: '/stories/14',
  },
];

const mockApps_cn = [
  {
    id: 1,
    title: 'PC围棋软件',
    category: '围棋',
    date: '2024-03-22',
    intro: '介绍一些好用的PC围棋软件',
    link: '/stories/3',
  },
  // Add more here
  {
    id: 2,
    title: '印刷问题',
    category: '印刷',
    date: '2024-04-08',
    intro: '一些印刷问题及其解决方案',
    link: '/stories/10',
  },
  {
    id: 3,
    title: 'Minecraft服务器',
    category: '服务器',
    date: '2024-04-13',
    intro: 'Minecraft服务器现已上线！',
    link: '/stories/11',
  },
  {
    id: 4,
    title: '战斗模拟器',
    category: '游戏',
    date: '2024-06-24',
    intro: '芦花的原创作品',
    link: '/stories/13',
  },
  {
    id: 5,
    title: '在Linux上進行故障排除',
    category: 'OS',
    date: '2024-08-14',
    intro: '处理Linux中的各种错误',
    link: '/stories/14',
  },
];

const mockApps_jp = [
    {
        id: 1,
        title: 'PC用囲碁アプリ',
        category: '囲碁',
        date: '2024-03-22',
        intro: '囲碁を楽しめる不可欠なPCアプリ',
        link: '/stories/3',
    },
    // Add more here
    {
      id: 2,
      title: '印刷の問題',
      category: '印刷',
      date: '2024-04-08',
      intro: 'いくつの印刷問題とその解決策',
      link: '/stories/10',
    },
    {
      id: 3,
      title: 'Minecraftサーバー',
      category: 'サーバー',
      date: '2024-04-13',
      intro: 'マイクラサーバーがついにオンラインになったぞ！',
      link: '/stories/11',
    },
    {
      id: 4,
      title: '戦闘シミュレーター',
      category: 'ゲーム',
      date: '2024-06-24',
      intro: '蘆花のオリジナル作品',
      link: '/stories/13',
    },
    {
      id: 5,
      title: 'Linuxでの故障診断',
      category: 'OS',
      date: '2024-08-14',
      intro: 'Linuxで様々なエラーに対処する。',
      link: '/stories/14',
    },
];

const Software = () => {
  // State to hold the search term
  const [language] = useState(localStorage.getItem('language') || 'jp');
  const [searchTerm, setSearchTerm] = useState('');

  // Function to handle change in search input
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Determine which mockApps to use based on the language
  let mockApps;
  if (language === 'cn') {
    mockApps = mockApps_cn;
  } else if (language === 'jp') {
    mockApps = mockApps_jp;
  } else {
    mockApps = mockApps_en;
  }

  // Filter stories based on the search term
  const filteredStories = mockApps.filter((story) => {
    return (
      story.title.toLowerCase().includes(searchTerm) ||
      story.category.toLowerCase().includes(searchTerm) ||
      story.date.includes(searchTerm) ||
      story.intro.toLowerCase().includes(searchTerm)
    );
  });

  return (
    <div>
      {/* Search input */}
      <input
        type="text"
        placeholder={translate('smartsearch', language)}
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />
    <div className="software-grid">
      {filteredStories.map((software) => (
        <div key={software.id} className="story-entry">
          <h3>{software.title}</h3>
          <p className="category-tag">{software.category}</p>
          <p className="date-of-publish">{software.date}</p>
          <p className="intro">{software.intro}</p>
          <a href={software.link}>{translate('read_more', language)}</a>
        </div>
      ))}
    </div>
  </div>
  );
};

export default Software;
const cn = {
    home: "首页",
    app: "应用",
    stories: "故事",
    smartsearch: "快速检索...",
    read_more: "了解更多",
    footer_msg1: "芦花是《雪境迷途遇仙踪》中的游戏角色",
    // Add more translations here
  };

  export default cn;
import React, { useState } from 'react';
import './posts.css'; 
import { translate } from '../../utilities/translate';

// Mock data for software releases, replace with real data fetching logic
const mockPosts_en = [
    {
        id: 1,
        title: 'The Secret Garden',
        category: 'Journal',
        date: '2024-03-26',
        intro: 'Welcome to my secret garden!',
        link: '/stories/5', 
    },
    {
      id: 2,
      title: 'The Whole Elephant',
      category: 'Investigation',
      date: '2024-03-27',
      intro: 'Imagining the future of the internet',
      link: '/stories/6',
    },
    {
      id: 3,
      title: 'The Hidden Hand',
      category: 'Fiction',
      date: '2024-04-16',
      intro: 'A imaginary secret government and climate change',
      link: '/stories/conspiracy',
    },
];

const mockPosts_cn = [
    {
        id: 1,
        title: '武陵桃源',
        category: '游记',
        date: '2024-03-26',
        intro: '欢迎来到桃源郷！',
        link: '/stories/5',
    },
    {
      id: 2,
      title: '全豹一斑',
      category: '调查',
      date: '2024-03-27',
      intro: '描绘网络世界的未来',
      link: '/stories/6',
    },
    {
      id: 3,
      title: '鬼影幢幢',
      category: '虚构故事',
      date: '2024-04-16',
      intro: '秘密政府和气候战争',
      link: '/stories/conspiracy',
    },
];

const mockPosts_jp = [
    {
        id: 1,
        title: '武陵桃源',
        category: '紀行',
        date: '2024-03-26',
        intro: '桃源郷へようこそ！',
        link: '/stories/5',
    },
    {
        id: 2,
        title: '全豹一斑',
        category: '調査',
        date: '2024-03-27',
        intro: 'ネットの未来を描いてみる',
        link: '/stories/6',
    },
    {
      id: 3,
      title: '鬼影幢々',
      category: 'フィクション',
      date: '2024-04-16',
      intro: '架空の秘密政府と気候戦争',
      link: '/stories/conspiracy',
    },
    {
      id: 4,
      title: '雨のファンタジー',
      category: '雑談',
      date: '2024-06-24',
      intro: 'ひらめー狐',
      link: '/stories/12',
    },
];

const Posts = () => {
  // State to hold the search term
  const [language] = useState(localStorage.getItem('language') || 'jp');
  const [searchTerm, setSearchTerm] = useState('');

  // Function to handle change in search input
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Determine which mockPosts to use based on the language
  let mockPosts;
  if (language === 'cn') {
    mockPosts = mockPosts_cn;
  } else if (language === 'jp') {
    mockPosts = mockPosts_jp;
  } else {
    mockPosts = mockPosts_en;
  }

  // Filter stories based on the search term
  const filteredStories = mockPosts.filter((story) => {
    return (
      story.title.toLowerCase().includes(searchTerm) ||
      story.category.toLowerCase().includes(searchTerm) ||
      story.date.includes(searchTerm) ||
      story.intro.toLowerCase().includes(searchTerm)
    );
  });

  return (
    <div>
      {/* Search input */}
      <input
        type="text"
        placeholder={translate('smartsearch', language)}
        value={searchTerm}
        onChange={handleSearchChange}
        className="search-input"
      />
    <div className="software-grid">
      {filteredStories.map((post) => (
        <div key={post.id} className="story-entry">
          <h3>{post.title}</h3>
          <p className="category-tag">{post.category}</p>
          <p className="date-of-publish">{post.date}</p>
          <p className="intro">{post.intro}</p>
          <a href={post.link}>{translate('read_more', language)}</a>
        </div>
      ))}
    </div>
  </div>
  );
};

export default Posts;